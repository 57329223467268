import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightSubtle, leftDark } from '../../constants/theme';

const Footer = ({ children }) => (
  <Container>
    <div>{children}</div>
  </Container>
);

const Container = styled.article`
  border-top: 1px solid ${leftDark};
  background: ${lightSubtle};
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    max-width: 1370px;
    margin-left: -5rem;
    & > * {
      margin-left: 5rem;
    }
    .map {
      flex: 0 1 600px;
    }
    .footer-info {
      padding: 1.3rem;
      font-size: 1.5rem;
      flex: 1;
      p {
        margin-bottom: 0;
      }
    }
  }
`;

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;
