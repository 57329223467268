import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { leftDark, mainRight } from 'constants/theme';

const Heading = ({ children, center, type }) => (
  <StyledHeading underscore={type} center={center}>
    <span>{children}</span>
  </StyledHeading>
);

const StyledHeading = styled.h3`
  display: block;
  font-size: 2.4rem;
  line-height: 29px;
  text-align: left;
  span {
    display: inline-block;
    padding: 0 3rem 1rem 0;
    margin-bottom: 3rem;
    border-bottom: 1px solid
      ${props => {
        if (props.underscore == 'primary') return leftDark;
        else return mainRight;
      }};
  }
  ${props =>
    props.center &&
    css`
      text-align: center;
      span {
        padding: 0 1.5rem 1rem 1.5rem;
      }
    `}
`;
Heading.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary']),
  center: PropTypes.bool,
};

export default Heading;
