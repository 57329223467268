import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import Left from 'images/controlLeft.svg';
import Right from 'images/controlRight.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

const Gallery = ({ images, alt }) => (
  <Carousel
    renderCenterLeftControls={({ previousSlide }) => (
      <Control>
        <Left onClick={previousSlide} />
      </Control>
    )}
    renderCenterRightControls={({ nextSlide }) => (
      <Control>
        <Right onClick={nextSlide} />
      </Control>
    )}
  >
    {images.map((image, i) => (
      <GatsbyImage image={image} key={i} alt={alt} />
    ))}
  </Carousel>
);

const Control = styled.div`
  cursor: pointer;
  margin: 0 10px;
  &:hover svg path {
    stroke-width: 3.5;
    transition: ease-out 0.3s all;
  }
`;

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  alt: PropTypes.string.isRequired,
};

export default Gallery;
