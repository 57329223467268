import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightSubtle, subtle } from '../../constants/theme';
import NewsIcon1 from 'images/news1.svg';
import NewsIcon2 from 'images/news2.svg';

const News = ({ news }) => {
  const newsElems = news.map(
    ({ frontmatter: { date, title }, rawMarkdownBody: text }, key) => (
      <div className="news-piece" key={key}>
        <table>
          <tbody>
            <tr>
              <td rowSpan="2">
                <div className="icon">
                  {key % 2 === 0 ? <NewsIcon1 /> : <NewsIcon2 />}
                </div>
              </td>
              <td>
                <span className="date">
                  {new Date(date).toLocaleDateString()}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h4 className="title">{title}</h4>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p className="body">{text}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
  return <StyledArticle>{newsElems}</StyledArticle>;
};

const StyledArticle = styled.article`
  flex: 1 1 40%;
  display: flex;
  flex-flow: column nowrap;
  min-width: 280px;
  margin-bottom: 2rem;
  .news-piece {
    border: 1px solid ${lightSubtle};
    padding: 1.5rem;
    & + div {
      margin-top: 2rem;
    }
  }
  table {
    width: 100%;
  }
  td {
    vertical-align: bottom;
  }
  tr:first-of-type > td:first-of-type {
    width: calc(2vw + 55px);
  }
  .date {
    color: ${subtle};
    font-size: 13px;
  }
  .title {
    font-weight: 600;
  }

  & .subtle {
    color: ${subtle};
    font-size: 13px;
  }
  p {
    margin-top: 2.5rem;
  }
`;

News.propTypes = {
  news: PropTypes.array.isRequired,
};

export default News;
