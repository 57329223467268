import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal, Table } from 'antd';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/table/style/index.css';
import { textDark, leftDark } from '../../constants/theme';
import { subtle } from 'constants/theme';

const InfoModal = ({ show, setShow }) => {
  const openingHours = [
    {
      key: '0',
      day: 'Po',
      hours: '7:00 - 11:30',
      info: '',
    },
    {
      key: '1',
      day: 'Út',
      hours: '12:30 - 16:30',
      info: '',
    },
    {
      key: '2',
      day: '',
      hours: '16:30 - 18:00 ',
      info: 'Objednaní',
    },
    {
      key: '3',
      day: 'St',
      hours: '7:00 - 11:30',
      info: '',
    },
    {
      key: '4',
      day: 'Čt',
      hours: '12:30 - 15:30',
      info: '',
    },
    {
      key: '5',
      day: '',
      hours: '15:30 - 16:30',
      info: 'Objednaní',
    },
    {
      key: '6',
      day: '',
      hours: '16:30 - 17:00 ',
      info: 'Návštěvy, administrativa',
    },
    {
      key: '7',
      day: 'Pá',
      hours: '7:00 - 11:30',
      info: '',
    },
    /* {
      key: '8',
      day: '',
      hours: '12:30 - 14:30',
      info: 'Návštěvy, administrativa',
    }, */
  ];

  const cols = [
    {
      title: 'Den',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'Hodiny',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Informace',
      dataIndex: 'info',
      key: 'info',
    },
  ];

  return show ? (
    <Modal
      visible
      mask
      footer={null}
      bodyStyle={{
        border: `2px solid ${leftDark}`,
      }}
      onCancel={e => {
        e.stopPropagation();
        setShow(false);
      }}
    >
      <Container>
        <h4>Ordinační hodiny</h4>
        <Table
          dataSource={openingHours}
          columns={cols}
          pagination={false}
          showHeader={true}
        />
        <p>
          V případně dotazů volejte na tel.:{' '}
          <a href="tel:+420 731 934 864">+420 731 934 864</a>
        </p>
      </Container>
    </Modal>
  ) : null;
};
InfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

const Container = styled.div`
  h4 {
    font-weight: 600;
  }
  p,
  h4 {
    margin: 12px 0 !important;
    font-size: 16px;
    color: ${textDark};
  }
`;

const Schedule = () => {
  const [showModal, setShowModal] = useState(false);

  const today = () => {
    const day = new Date().getDay();
    if (day > 0 && day < 6) return day - 1;
    else return 0;
  };
  const days = ['Po', 'ǔt', 'St', 'Čt', 'Pá'];

  const openingHoursShort = [
    '7:00 - 11:30',
    '12:30 - 18:00',
    '7:00 - 11:30',
    '12:30 - 17:00',
    '7:00 - 11:30',
  ];

  return (
    <>
      <dd>
        {days[today()]}: {openingHoursShort[today()]}
      </dd>
      <dd>
        <ResetedButton onClick={() => setShowModal(true)}>
          Více info / další dny
        </ResetedButton>
      </dd>
      <InfoModal show={showModal} setShow={setShowModal} />
    </>
  );
};

export default Schedule;

const ResetedButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: ${subtle};
  font: inherit;

  line-height: normal;

  text-decoration: underline;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;
