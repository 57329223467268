import styled from 'styled-components';
import { mainLeft, mainCenter, mainRight, leftDark } from 'constants/theme';

export const Container = styled.header`
  background: linear-gradient(
    60deg,
    ${mainLeft} 0%,
    ${mainCenter} 50%,
    ${mainRight} 100%
  );
  min-height: 70vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .box {
    min-height: 60vh;
    padding: 5rem 0;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    max-width: 450px;
  }

  h1 {
    font-size: 3.2rem;
    padding: 0 15px 10px 15px;
    font-family: Nunito, sans-serif;
    text-transform: uppercase;
    margin-bottom: 4.5rem;
    border-bottom: 1px solid ${leftDark};
  }

  h2 {
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: bold;
    letter-spacing: -0.5px;
    margin-bottom: 5rem;
    max-width: 325px;
  }

  p {
    text-align: center;
    font-size: 1.85rem;
    letter-spacing: -0.3px;
    line-height: 2.4rem;
    margin-bottom: 5rem;
  }
  .box > div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 5rem;
  }
  svg {
    max-width: 100%;
  }
`;
