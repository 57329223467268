import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Header from 'components/header';
import Button from 'components/button';
import Layout from 'components/layout';
import Box from 'components/box';
import Heading from 'components/heading';
import Info from 'components/info';
import News from 'components/news';
import Staff from 'components/staff';
import About from 'components/about';
import { graphql } from 'gatsby';
import Footer from 'components/footer';
import IO from 'components/io';
import InfoModal from 'components/modal';
import Schedule from 'components/schedule';

const Index = ({ data }) => {
  const [showModal, setShowModal] = useState(true);
  return (
    <Layout>
      <InfoModal show={showModal} setShow={setShowModal} images={data} />
      <Header>
        <div>
          <Button scrollTarget="#news">Aktuality</Button>
          <Button scrollTarget="#about">Více o nás</Button>
        </div>
      </Header>
      <Box cols>
        <Info>
          <div id="info">
            <Heading center type="primary">
              Rychlé info
            </Heading>
          </div>
          <dl>
            <dt>Adresa</dt>
            <dd>Trávnická 2198/2, 796 01 Prostějov</dd>
            <dd>
              <a
                className="subtle"
                href="https://www.google.com/maps/place/Medkal/@49.4712903,17.117807,17z/data=!4m12!1m6!3m5!1s0x47125a30b5fb20ad:0x101dafcd1bf9b14a!2sMedkal!8m2!3d49.4712868!4d17.120001!3m4!1s0x47125a30b5fb20ad:0x101dafcd1bf9b14a!8m2!3d49.4712868!4d17.120001"
              >
                Zobrazit mapu →
              </a>
            </dd>
          </dl>
          <dl>
            <dt>Ordinační hodiny</dt>
            <Schedule />
          </dl>
          <dl>
            <dt>Smluvní pojišťovny</dt>
            <dd>VZP, ZPMV, OZP, VoZP, ČPZP</dd>
          </dl>
          <dl>
            <dt>Telefon</dt>
            <dd>
              <a href="tel:+420 731 934 864">+420 731 934 864</a>
            </dd>
          </dl>
          <dl>
            <dt>E-mail</dt>
            <dd>
              <a href="mailto: medkal@seznam.cz">medkal@seznam.cz</a>
            </dd>
          </dl>
          <p className="subtle">
            Nebereme-li zrovna telefon v době ordinačních hodin, pravděpodobně
            se právě staráme o pacienta. V takovémto případě prosíme zavoltejte
            později. Děkujeme.
          </p>
        </Info>
        <div style={{ flex: '1 1 40%', boxSizing: 'border-box' }}>
          <div id="news">
            <Heading center type="secondary">
              Aktuality
            </Heading>
          </div>
          <News news={data.allMarkdownRemark.nodes} />
        </div>
      </Box>
      <IO>
        {({ isVisible, hasBeenVisible }) => (
          <Box>
            <div id="about">
              <Heading center type="primary">
                Naše ordinace
              </Heading>
            </div>
            <About
              images={data.allImageSharp.nodes.map(
                ({ gatsbyImageData }) => gatsbyImageData
              )}
              visible={{ isVisible, hasBeenVisible }}
            >
              <p>
                Poskytujeme léčebnou a preventivní péči pro děti a dorost včetně
                povinného očkování, odběrů nebo např. CRP testů.
              </p>
              <p>
                Naše ordinace se nachází ve druhém nadzemním podlaží
                zdravotnického centra na ul. Trávnická v centru Prostějova.
                Můžete se u nás těšit z příjemné a komplexně vybavené ordinace,
                kterou jsme v roce 2017 společně s čekárnou kompletně
                zmodernizovali tak, aby se v ní cítili dobře jak děti, tak
                jejich rodiče.
              </p>
              <p>
                V naší ordinaci usilujeme o poskytování co možná nejkvalitněší
                zdravotní péče s přátelským přístupem. Máme rozsáhlé zkušenosti
                s lékařskou péčí o novorozence i starší pacienty do 19 let věku.
                Přijděte se k nám podívat v době ordinačních hodin, nebo nám
                zavolejte či napište.
              </p>
              <p>Těšíme se na vás!</p>
            </About>
          </Box>
        )}
      </IO>
      <Box>
        <Heading center type="secondary">
          Personál
        </Heading>
        <Staff staff={data.allStaffJson.nodes}></Staff>
      </Box>
      <Footer>
        <div className="footer-info">
          <p>
            Provozovatelem ordinace je společnost Medkal s.r.o., IČ 29266521, se
            sídlem: Trávnická 2198/2, 796 01 Prostějov, zapsaná u Krajského
            soudu v Brně pod sp.zn.: C 69383/KSBR.
          </p>
        </div>
        {/*  <div className="map">
        <a href={data.staticMap.mapUrl}>
          <Img
            fluid={data.staticMap.childFile.childImageSharp.fluid}
            alt="mapa"
          />
        </a>
      </div> */}
      </Footer>
    </Layout>
  );
};
Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 500)
        fluid(maxWidth: 500, quality: 90)  {
          ...GatsbyImageSharpFluid
          originalName
        }
      }
    }
    allStaffJson(sort: { fields: order, order: ASC }, limit: 2) {
      nodes {
        name
        role
        bioShort
        bioFull
      }
    }
    allMarkdownRemark(
      limit: 2
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/_news/" } }
    ) {
      nodes {
        frontmatter {
          date
          title
        }
        rawMarkdownBody
      }
    }
  }
`;

export default Index;
