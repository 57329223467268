import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BP from '../../constants/breakpoints';
import Gallery from 'components/gallery-carousel';

const About = ({ children, images, visible }) => {
  const [height, setHeight] = useState(0);
  const wasVisible = visible.isVisible || visible.hasBeenVisible;
  const handleResize = () => {
    setHeight({
      gallery: document.querySelector('#gallery').offsetHeight,
      text: document.querySelector('#about-text').offsetHeight,
    });
  };
  useEffect(handleResize, [wasVisible]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <Container height={height}>
      <div>
        <div className="gallery-cont">
          <div id="gallery">
            <Gallery images={images} alt="Naše ordinace" />
          </div>
        </div>
        <div className="text" id="about-text">
          {children}
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    </Container>
  );
};
export const Container = styled.section`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  text-align: justify;
  .gallery-cont {
    float: right;
    position: relative;
    z-index: 2;
    width: 500px;
    padding: 0 0 2rem 3rem;
    @media screen and (max-width: ${BP.TABLET}px) {
      width: 100%;
    }
  }
  .text {
    height: 100%;
    position: relative;
    ${({ height: { gallery, text } }) => {
      if (text < gallery) {
        return css`
          padding-top: ${(gallery - text) / 2}px;
        `;
      }
    }}
  }
  h4 {
    font-weight: 600;
  }
`;

About.propTypes = {
  children: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  visible: PropTypes.object.isRequired,
};

export default About;
